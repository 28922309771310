import(/* webpackMode: "eager" */ "/home/corleone/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/@mui/material/Paper/index.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/next/dist/client/link.js");
